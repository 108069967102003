import { useTranslation } from "react-i18next"
import { useParams } from "react-router"

import { AlertLoadingError } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import { config } from "@/config"

import { useFetchCampaign } from "../api/campaigns"
import { useFetchStudents } from "../api/students"
import { CampaignDetails } from "../campaigns-types"
import { CreateEditPageLayout } from "../shared/create-edit-page-layout"
import { useEditCampaignState } from "./edit-filters-hooks"
import { FiltersPanel, LoadingFiltersPanel } from "./filters-panel"
import { LoadingViewStudentsPreview, PreviewPanel } from "./preview-panel"
import { PriorityFiltersPanel } from "./priority-filters-panel"

export function EditCampaignPage() {
  const { campaign_id } = useParams()
  if (!campaign_id) throw new Error("Missing campaign_id")
  const campaignQueryState = useFetchCampaign(campaign_id)
  const { data, error, isError, isLoading } = campaignQueryState

  if (isLoading) {
    return (
      <CreateEditPageLayout
        campaign_id={campaign_id}
        actionBar={<Button className="invisible" />}
        filtersArea={<LoadingFiltersPanel />}
        header={<>{campaign_id}</>}
        previewArea={<LoadingViewStudentsPreview />}
      />
    )
  }

  if (isError) {
    return (
      <CreateEditPageLayout
        campaign_id={campaign_id}
        header={<>{campaign_id}</>}
        previewArea={
          <AlertLoadingError
            error={error}
            title="Unable to load the campaign filters"
          />
        }
      />
    )
  }

  const campaign = data?.data
  if (!campaign) return null

  return <ViewCampaignFilters campaign={campaign} />
}

function ActionBar({
  isSaving,
  onCancel,
  onSave,
}: {
  isSaving?: boolean
  onCancel?: () => void
  onSave?: () => void
}) {
  const { t } = useTranslation()

  return (
    <div className="flex gap-4">
      <Button
        variant="outline"
        isDisabled={!onCancel || isSaving}
        onClick={onCancel}
      >
        {t("global.button.cancel")}
      </Button>
      <Button
        variant="default"
        isDisabled={!onSave || isSaving}
        onClick={onSave}
        isLoading={isSaving}
      >
        {t("global.button.save")}
      </Button>
    </div>
  )
}

function ViewCampaignFilters({ campaign }: { campaign: CampaignDetails }) {
  const {
    filtersState,
    handleCancel,
    handleSave,
    hasFilters,
    isChanged,
    mutation,
    priorityFiltersState,
  } = useEditCampaignState(campaign)

  const fetchStudentsQuery = useFetchStudents(
    hasFilters,
    filtersState.filters,
    priorityFiltersState.filters,
  )

  const studentCount = fetchStudentsQuery.data?.data?.total_student_count || 0

  const disableSaveButton =
    !hasFilters ||
    !isChanged ||
    fetchStudentsQuery.isLoading ||
    fetchStudentsQuery.isError ||
    studentCount > config.maxStudentNumber

  return (
    <CreateEditPageLayout
      campaign_id={campaign.campaign_id}
      actionBar={
        <ActionBar
          isSaving={mutation.isPending}
          onSave={disableSaveButton ? undefined : handleSave}
          onCancel={isChanged ? handleCancel : undefined}
        />
      }
      header={<>{campaign.campaign_id}</>}
      filtersArea={
        <FiltersPanel campaign_id={campaign.campaign_id} {...filtersState} />
      }
      priorityFiltersArea={
        <PriorityFiltersPanel
          campaign_id={campaign.campaign_id}
          {...priorityFiltersState}
          filters={filtersState.filters}
          priorityFilters={priorityFiltersState.filters}
          setPriorityFilters={priorityFiltersState.setFilters}
        />
      }
      previewArea={
        <PreviewPanel
          hasFilters={hasFilters}
          studentsQuery={fetchStudentsQuery}
        />
      }
    />
  )
}
