import { useLocation } from "react-router"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { RESET } from "jotai/utils"

import { routesConfig } from "@/config/routes"
import {
  currentContactStatusTypeAtom,
  currentContactTypeAtom,
  inManualCallAtom,
  pseudoACWAtom,
} from "@/helpers/atoms"
import { contactStorageService } from "@/services/localStorageService"

type UseManualCallHookType = {
  resetInManualCall: () => void
  resetManualCallWhenOnline: (newState: connect.AgentStateType) => void
  shouldClearAllStateInLocalStorage: boolean
}

const useManualCallAtom = (): UseManualCallHookType => {
  const setInManualCall = useSetAtom(inManualCallAtom)
  const currentContactType = useAtomValue(currentContactTypeAtom)
  const currentContactStatusType = useAtomValue(currentContactStatusTypeAtom)
  const [pseudoACW, setPsedoACW] = useAtom(pseudoACWAtom)

  const location = useLocation()

  const isACW =
    (currentContactType === connect.ContactType.VOICE &&
      currentContactStatusType === connect.ContactStateType.ENDED) ||
    pseudoACW
  /* Keep the processing time when logging out if we have a voice contact in progress (ACW state)
   in the automatic call panel */
  const withContactInProgress =
    isACW && location.pathname === routesConfig.AUTOMATIC_CALL_PANEL
  const shouldClearAllState = !withContactInProgress

  const resetInManualCall = () => {
    setInManualCall(RESET)
    setPsedoACW(RESET)
    contactStorageService.removeManualCallCase()
  }

  /* Erase isManualCall value when switching to the automatic calling panel
     to prevent bugs in the `contact.onDestroy()` event handler */
  const resetManualCallWhenOnline = (newState: connect.AgentStateType) => {
    if (newState === connect.AgentStateType.ROUTABLE) {
      resetInManualCall()
    }
  }

  return {
    resetManualCallWhenOnline,
    shouldClearAllStateInLocalStorage: shouldClearAllState,
    resetInManualCall,
  }
}

export { useManualCallAtom }
