import { FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import Icon from "@/components/Icon"
import { Button } from "@/components/ui/button"
import { config } from "@/config/index"

import { type QueueRefillErrorContext } from "./value"

interface Props {
  errorContext: QueueRefillErrorContext
}

const ResetCallViewOnQueueRefillError: FunctionComponent<Props> = ({
  errorContext,
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(
    errorContext === "queue-refill-rate-limit-error" ? true : false,
  )

  const title = t("callPanel.resetState.queueRefill.title")
  const subtitle = t(`callPanel.resetState.queueRefill.${errorContext}`)

  const onClick = () => {
    setIsLoading(true)
    window.location.reload()
    setIsLoading(false)
  }

  // Sets a timer to re-enable the button after 20 seconds when the agent has encountered an API rate limit error
  useEffect(() => {
    if (errorContext === "queue-refill-rate-limit-error") {
      const timer = setTimeout(() => {
        setIsDisabled(false)
      }, config.queueRefillRateLimitSeconds * 1000)

      return () => clearTimeout(timer)
    }
  }, [errorContext])

  return (
    <div
      className="m-auto flex h-full w-2/3 flex-col items-center gap-y-6 px-8 pb-8 pt-36"
      data-sentry-unmask
    >
      <Icon name="alert" size="md" className="text-danger-200" />
      <div className="flex flex-col gap-y-2 text-neutral-700">
        <h1 className="font-h1-bold">{title}</h1>
        <p className="font-body-large-regular">{subtitle}</p>
      </div>
      <Button
        type="button"
        onClick={onClick}
        isDisabled={isDisabled || isLoading}
        loadingMessage={t("global.loadingResetView")}
      >
        {t("global.button.refreshPage")}
      </Button>
    </div>
  )
}

export default ResetCallViewOnQueueRefillError
