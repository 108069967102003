import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link } from "react-router"

import Input from "@/components/core/Input"
import Icon from "@/components/Icon"
import { Button } from "@/components/ui/button"
import { cn } from "@/helpers/classNames"
import { useFormValidation } from "@/hooks/formValidation"

import { useLoginHook } from "./hook"

const styles = {
  container:
    "min-h-screen w-full flex flex-col justify-center items-center gap-y-6 bg-neutral-200",
  brand: "mb-2",
  subtitle: "font-body-xlarge-bold text-neutral-600",
  formContainer:
    "flex flex-col gap-y-8 justify-center items-center p-8 w-2/5 min-w-fit bg-neutral-100 border border-neutral-300 rounded shadow-card",
  inputsContainer: (isDisabled: boolean) =>
    cn([
      "flex w-full flex-col gap-y-4",
      isDisabled && "pointer-events-none opacity-50",
    ]),
  inputLabel: "mb-1 font-label-small text-neutral-600",
  changeInputTypeIcon: "w-4 h-4 cursor-pointer text-primary-300",
  forgotLink: "text-primary-300 font-button-small p-1 self-start",
  submit: "w-full max-w-[240px]",
  forgotContainer: "text-neutral-600 font-body-medium-regular text-center",
  contactManager: "font-body-medium-bold",
  errorContainer:
    "flex items-center gap-x-1 w-2/5 px-4 py-6 border rounded border-danger-300 bg-danger-200 text-neutral-100 font-h4-bold",
}

const Login = () => {
  const { t } = useTranslation()

  const {
    form,
    isLoading,
    onChangePasswordInputType,
    onSubmit,
    passwordInputType,
    submitError,
  } = useLoginHook()

  const {
    clearErrors,
    control,
    formState: { errors },
  } = form

  const { isValidPassword, validEmailAddress } = useFormValidation()

  return (
    <div className={styles.container}>
      <div className={styles.brand}>
        <Icon name="logo" size="logo-size" />
      </div>
      <p className={styles.subtitle} data-unmask>
        {t("login.subtitle")}
      </p>
      {!!submitError && (
        <div className={styles.errorContainer} data-unmask>
          <Icon name="alert" size="sm" />
          <h4>{submitError}</h4>
        </div>
      )}
      <form className={styles.formContainer} onSubmit={onSubmit}>
        <div className={styles.inputsContainer(isLoading)}>
          <Controller
            control={control}
            rules={{ required: true, validate: { validEmailAddress } }}
            name="username"
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <Input
                name={name}
                label="login.emailLabel"
                type="email"
                placeholder="login.emailPlaceholder"
                onBlur={onBlur}
                onChange={onChange}
                errors={errors}
                clearErrors={clearErrors}
                forwardedRef={ref}
                value={value}
                orientation="vertical"
                labelClassName={styles.inputLabel}
              />
            )}
          />
          <Controller
            control={control}
            rules={{ required: true, validate: { isValidPassword } }}
            name="password"
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <Input
                name={name}
                label="login.passwordLabel"
                labelClassName={styles.inputLabel}
                type={passwordInputType}
                placeholder="login.passwordPlaceholder"
                onBlur={onBlur}
                onChange={onChange}
                errors={errors}
                clearErrors={clearErrors}
                forwardedRef={ref}
                value={value}
                target={
                  <Icon
                    name={passwordInputType === "text" ? "eye" : "eye-slash"}
                    size="sm"
                    className={styles.changeInputTypeIcon}
                    onClick={onChangePasswordInputType}
                  />
                }
                orientation="vertical"
                targetPosition="right"
              />
            )}
          />
          <div className={styles.forgotLink}>
            <Link to="/reset-password/request">
              <span data-unmask>{t("login.forgotPassword")}</span>
            </Link>
          </div>
        </div>
        <Button
          size="lg"
          type="submit"
          className={styles.submit}
          isDisabled={isLoading}
          isLoading={isLoading}
          loadingMessage={t("login.loadingText")}
        >
          {t("global.button.login")}
        </Button>
      </form>
      <div className={styles.forgotContainer} data-unmask>
        <p>{t("login.help")}</p>
        <p className={styles.contactManager}>{t("login.contactManager")}</p>
      </div>
    </div>
  )
}

export default Login
