import { Link, LinkProps, useSearchParams } from "react-router"

export default function SearchLink({
  children,
  pathname,
  ...rest
}: Omit<LinkProps, "to"> & { pathname: string }) {
  const [searchParams] = useSearchParams()

  const search = new URLSearchParams()
  search.set("date_from", searchParams.get("date_from") || "")
  search.set("date_to", searchParams.get("date_to") || "")

  return (
    <Link
      to={{ pathname, search: search.toString() }}
      className="hover:text-primary-300"
      {...rest}
    >
      {children}
    </Link>
  )
}
