import { Link } from "react-router"

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { cn } from "@/helpers/classNames"

export function TableLink({
  className,
  ...props
}: React.ComponentProps<typeof Link>) {
  return (
    <Link
      {...props}
      className={cn(
        "underline decoration-table-link decoration-dashed underline-offset-8 hover:text-primary-300 hover:decoration-table-link-emphasized",
        className,
      )}
    />
  )
}

/**
 * Truncate to a certain number of characters and show the full text on hover
 */
export function TruncatedTextCell({
  numberOfChar,
  text,
}: {
  numberOfChar: number
  text: string
}) {
  const isTruncated = text.length > numberOfChar
  const truncatedText = isTruncated ? text.slice(0, numberOfChar) + "..." : text
  if (!isTruncated) return <>{text}</>

  return (
    <Tooltip>
      <TooltipTrigger>{truncatedText}</TooltipTrigger>
      <TooltipContent className="max-w-100">{text}</TooltipContent>
    </Tooltip>
  )
}

/**
 * Given a nullable value and a render function, render the content or the common placeholder for empty values
 */
export function CellContent<T>({
  render,
  value,
}: {
  render: (value: NonNullable<T>) => JSX.Element | string
  value: T
}) {
  return value ? <>{render(value)}</> : <EmptyValue />
}

/**
 * The common placeholder for empty values. This is not the usual dash `-`
 */
export function EmptyValue() {
  return <span className="text-neutral-500">—</span>
}
