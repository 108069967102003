import { PropsWithChildren } from "react"
import { Link } from "react-router"
import { ChevronLeft, ChevronRight } from "lucide-react"

import { Button, buttonVariants } from "@/components/ui/button"
import { cn } from "@/helpers/classNames"

import { PaginationState, usePagination } from "./pagination-state"

export function PaginationBar({ page, pageSize, total }: PaginationState) {
  const paginationState = usePagination({ page, pageSize, total })

  return <StatelessPaginationBar {...paginationState} />
}

export function StatelessPaginationBar({
  getNextPagePath,
  getPreviousPagePath,
  page = 1,
  pageSize = 20,
  total,
}: PaginationState & {
  getNextPagePath: () => string
  getPreviousPagePath: () => string
}) {
  const start = (page - 1) * pageSize + 1
  const end = Math.min(page * pageSize, total)

  return (
    <div className="flex h-10 items-center justify-end gap-1 border border-neutral-300 bg-neutral-100 px-2">
      {start} - {end} / {total}
      <PaginationButton path={getPreviousPagePath()} disabled={page === 1}>
        <ChevronLeft />
      </PaginationButton>
      <PaginationButton
        path={getNextPagePath()}
        disabled={page * pageSize >= total}
      >
        <ChevronRight />
      </PaginationButton>
    </div>
  )
}

/**
 * Render a link styled as a button if navigation is allowed, a disabled button otherwise
 */
function PaginationButton({
  children,
  disabled,
  path,
}: PropsWithChildren<{ disabled: boolean; path: string }>) {
  if (disabled) {
    return (
      <Button variant="ghost" size="icon" disabled>
        {children}
      </Button>
    )
  }

  return (
    <Link
      to={path}
      className={cn(
        buttonVariants({ variant: "ghost", size: "icon" }),
        "size-8",
      )}
    >
      {children}
    </Link>
  )
}
