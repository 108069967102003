import { useState } from "react"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { omit } from "es-toolkit"

import { config } from "@/config"
import { useLogger } from "@/hooks/useLogger"

import { SaveFiltersProps, useUpdateFilters } from "../api/campaigns"
import { CampaignDetails, FilterSelections } from "../campaigns-types"

type EditableCampaignData = {
  filters: FilterSelections
  priorityFilters: FilterSelections | null
}

export function useCreateCampaignState(campaign_id: string | null) {
  const useNewTaskSystem = config.enableCampaignV2

  return useCampaignState({
    campaign_id,
    initialFilters: {},
    initialPriorityFilters: null,
    useNewTaskSystem,
  })
}

export function useEditCampaignState(campaign: CampaignDetails) {
  const { campaign_filter_selection, campaign_id, priority_filter_selection } =
    campaign

  return useCampaignState({
    campaign_id,
    initialFilters: campaign_filter_selection,
    initialPriorityFilters: priority_filter_selection,
    useNewTaskSystem: campaign.use_new_task_system || false,
  })
}

function useCampaignState({
  campaign_id,
  initialFilters,
  initialPriorityFilters,
  useNewTaskSystem,
}: {
  campaign_id: string | null
  initialFilters?: FilterSelections
  initialPriorityFilters?: FilterSelections | null
  useNewTaskSystem: boolean
}) {
  const filtersState = useFilterState(initialFilters || {})

  const priorityFiltersState = useFilterState(initialPriorityFilters || {})

  const { filters } = filtersState
  const priorityFilters = priorityFiltersState.filters

  // Track the filters saved to the database to show the Save button only when the user changes something
  const [savedFilters, setSavedFilters] = useState<EditableCampaignData>({
    filters: initialFilters || {},
    priorityFilters: initialPriorityFilters || {},
  })

  const isChanged = !areSameFilters({ filters, priorityFilters }, savedFilters)

  const { mutation, save } = useSaveFilters({
    campaign_id,
    filters,
    priorityFilters,
    useNewTaskSystem,
  })

  async function handleSave() {
    if (await save()) {
      setSavedFilters({ filters, priorityFilters })
    }
  }

  function handleCancel() {
    filtersState.resetAllFilters()
    priorityFiltersState.resetAllFilters()
  }

  const hasFilters = Object.keys(filters).length > 0 // TODO do we need to check empty arrays?

  return {
    filtersState,
    priorityFiltersState,
    isChanged,
    handleSave,
    handleCancel,
    mutation,
    hasFilters,
  }
}

type FilterState = {
  addedFilters: Array<keyof FilterSelections>
  filters: FilterSelections
}

/**
 * Low level hook to handle the logic of the filters
 * and track filters being added on the sidebar before any value is set.
 */
export function useFilterState(initialState: FilterSelections) {
  const [state, setState] = useState<FilterState>({
    filters: initialState,
    addedFilters: [],
  })

  function addFilter(filterKey: keyof FilterSelections) {
    return setState((current) => ({
      ...current,
      addedFilters: [...current.addedFilters, filterKey],
    }))
  }

  function setFilters(
    setFilters: (value: FilterSelections) => FilterSelections,
  ) {
    return setState((current) => ({
      ...current,
      filters: setFilters(current.filters),
    }))
  }

  function removeFilterKeepVisible(filterKey: keyof FilterSelections) {
    return setState((current) => ({
      filters: omit(current.filters, [filterKey]),
      addedFilters: [...current.addedFilters, filterKey],
    }))
  }

  function removeFilterAndHide(filterKey: keyof FilterSelections) {
    return setState((current) => ({
      filters: omit(current.filters, [filterKey]),
      addedFilters: current.addedFilters.filter((key) => key !== filterKey),
    }))
  }

  function isVisibleFilter(filterKey: keyof FilterSelections) {
    return state.addedFilters.includes(filterKey) || !!state.filters[filterKey]
  }

  function resetAllFilters() {
    setState({ filters: initialState, addedFilters: [] })
  }

  return {
    filters: state.filters,
    addFilter,
    setFilters,
    removeFilterKeepVisible,
    removeFilterAndHide,
    isVisibleFilter,
    resetAllFilters,
  }
}

function useSaveFilters({
  campaign_id,
  filters,
  priorityFilters,
  useNewTaskSystem,
}: SaveFiltersProps) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const log = useLogger()

  const updateFilters = useUpdateFilters()

  const mutation = useMutation({
    mutationFn: async () => {
      if (!campaign_id) throw new Error("No campaign ID") //TODO refactor
      await updateFilters({
        campaign_id,
        filters,
        priorityFilters,
        useNewTaskSystem,
      })
    },
  })

  async function save() {
    try {
      await mutation.mutateAsync()
      toast.success(t("campaigns.filters.success"))
      queryClient.invalidateQueries({ queryKey: ["campaigns"] })
      navigate(`/campaigns`)
    } catch (error) {
      toast.error("Unable to save filters")
      log.error(error)

      return false
    }
  }

  return { save, mutation }
}

function areSameFilters(a: EditableCampaignData, b: EditableCampaignData) {
  return JSON.stringify(a) === JSON.stringify(b)
}
