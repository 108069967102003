import { useMemo, useState } from "react"
import { DateRange } from "react-day-picker"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { ChevronLeft, LoaderCircle } from "lucide-react"
import invariant from "tiny-invariant"

import { ColumnGroupDef } from "@/components/DashboardTable"
import { AlertLoadingError } from "@/components/ui/alert"
import { Combobox } from "@/components/ui/combobox"
import { RangePicker } from "@/components/ui/datepicker"
import { formatFullName } from "@/helpers/names"
import { SentryLogger } from "@/helpers/sentryLogger"
import { isNull, isUndefined } from "@/helpers/typeguards"
import { useAgentProfile } from "@/hooks/agent-profile"

import { formatColumnsFromMetadata } from "../column-utils"
import {
  useFetchAgentPerformanceByCampaign,
  useFetchAgentsPerformance,
  useFilterParams,
} from "../hooks"
import SearchLink from "../search-link"
import { StatefulTable } from "../stateful-table"
import { Decoder } from "../value"
import AgentMetricsCardRow from "./agent-metrics-row"

const sentryLogger = new SentryLogger()

export default function DetailsByCampaignPage({
  agentProfile,
  entry,
}: {
  agentProfile?: ReturnType<typeof useAgentProfile>["agentProfile"]
  entry: "personal" | "staff"
}) {
  if (entry === "staff") {
    return <StaffPage />
  }
  if (!agentProfile) {
    return <LoaderCircle className="m-4 animate-spin text-primary-300" />
  }

  return <Personal staff_id={agentProfile.id} />
}

function StaffPage() {
  const { t } = useTranslation()
  const { staff_id } = useParams<{ staff_id: string }>()
  invariant(staff_id, "staff_id is required")
  const pageState = usePageState(staff_id)

  return (
    <main className="flex h-full flex-col gap-y-4 px-8 pt-10">
      <SearchLink
        pathname="/performance/staff"
        className="flex cursor-pointer flex-row items-center"
      >
        <ChevronLeft />
        <span>{t("performance.tabs.staff")}</span>
      </SearchLink>
      <div className="flex items-center justify-between">
        <AgentFullname
          staff_id={staff_id}
          agentFetchResult={pageState.agentFetchResult}
        />
      </div>
      <AgentMetricsMainContent pageState={pageState} />
    </main>
  )
}

function Personal({ staff_id }: { staff_id: string }) {
  const pageState = usePageState(staff_id)

  return (
    <div className="flex flex-col gap-y-4 pt-2">
      <AgentMetricsMainContent pageState={pageState} />
    </div>
  )
}

function AgentMetricsMainContent({
  pageState,
}: {
  pageState: ReturnType<typeof usePageState>
}) {
  const {
    agentFetchResult,
    campaignId,
    campaignsFetchResult,
    dateRange,
    onFilter,
    setDateRange,
  } = pageState

  return (
    <>
      <RangePicker date={dateRange} onChange={setDateRange} />
      <MetricsContent
        filterCampaignId={campaignId || null}
        onFilter={onFilter}
        agentResult={agentFetchResult}
        campaignListResult={campaignsFetchResult}
      />
    </>
  )
}

function MetricsContent({
  agentResult,
  campaignListResult,
  filterCampaignId,
  onFilter,
}: {
  agentResult: ReturnType<typeof useFetchAgentsPerformance>
  campaignListResult: ReturnType<typeof useFetchAgentPerformanceByCampaign>
  filterCampaignId: string | null
  onFilter: (campaign_id: string | null) => void
}) {
  const { t } = useTranslation()
  const [columnError, setColumnError] = useState<string | null>(null)

  const {
    data: campaignListData,
    error: campaignListError,
    isError: isCampaignListError,
    isLoading: isCampaignListLoading,
  } = campaignListResult

  const campaignColumns = useMemo(() => {
    if (isUndefined(campaignListData) || isUndefined(campaignListData.data)) {
      return []
    }
    try {
      const firstColumn: ColumnGroupDef<Decoder.CampaignMetricItem> = {
        header: t("performance.table.campaignName"),
        cols: [
          {
            id: "campaign",
            subHeader: "",
            cell: (data) => <>{data.campaignId}</>,
          },
        ],
      }

      return [
        firstColumn,
        ...formatColumnsFromMetadata(
          campaignListData.data.parentMetricsMetadata,
          campaignListData.data.metadata,
        ),
      ]
    } catch (error) {
      if (error instanceof Error) {
        sentryLogger.error(error.message)
        setColumnError(error.message)
      }

      return []
    }
  }, [campaignListData, t])

  const formattedMetrics = campaignListData?.data?.metrics || []

  const campaignOptions =
    formattedMetrics?.map((campaign) => ({
      value: campaign.campaignId,
      label: campaign.campaignId,
    })) || []

  const displayCampaignList = isNull(filterCampaignId)
    ? formattedMetrics
    : formattedMetrics.filter((item) => item.campaignId === filterCampaignId)

  if (columnError) {
    return (
      <AlertLoadingError
        error={new Error()}
        title={t("performance.error.invalidFormat")}
      />
    )
  }

  return (
    <>
      <AgentMetricsCardRow agentResult={agentResult} />
      <h4 className="font-h4-bold text-neutral-700">
        {t("performance.detail.campaignTitle")}
      </h4>
      <Combobox<string, true>
        size="lg"
        options={campaignOptions}
        value={filterCampaignId}
        onChange={onFilter}
        placeholder={t("performance.placeholder.search")}
        allowReset={true}
      />
      <StatefulTable
        isLoading={isCampaignListLoading}
        error={isCampaignListError ? (campaignListError as Error) : null}
        columnGroups={campaignColumns}
        items={displayCampaignList}
        getItemId={(item) => item.campaignId}
      />
    </>
  )
}

function usePageState(staff_id: string) {
  const [searchParams, updateQueryParams] = useFilterParams()

  const campaignsFetchResult = useFetchAgentPerformanceByCampaign(
    staff_id,
    searchParams.dateRange,
  )
  const agentFetchResult = useFetchAgentsPerformance(
    searchParams.dateRange,
    staff_id,
  )

  return {
    dateRange: searchParams.dateRange,
    setDateRange: (dateRange: DateRange | undefined) =>
      updateQueryParams({ dateRange }),
    campaignId: searchParams.campaign_id,
    onFilter: (campaign_id: string | null) =>
      updateQueryParams({ campaign_id: campaign_id ?? undefined }),
    campaignsFetchResult,
    agentFetchResult,
  }
}

function AgentFullname({
  agentFetchResult,
  staff_id,
}: {
  agentFetchResult: ReturnType<typeof useFetchAgentsPerformance>
  staff_id?: string
}) {
  if (!staff_id) {
    return null
  }
  const agentMetadata = agentFetchResult?.data?.data?.agentMetadata?.[
    staff_id
  ] ?? { FirstName: "", LastName: "" }

  const fullName = formatFullName(
    agentMetadata.FirstName,
    agentMetadata.LastName,
  )

  return (
    <h1 className="font-h2-bold text-neutral-700">
      {agentFetchResult.isLoading ? (
        <LoaderCircle className="animate-spin text-primary-300" />
      ) : fullName === "-" ? (
        staff_id
      ) : (
        fullName
      )}
    </h1>
  )
}
