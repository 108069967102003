import { FormEventHandler, useState } from "react"
import { SubmitHandler, useForm, UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router"
import { SignInOutput } from "@aws-amplify/auth"

import { useAnalytics } from "@/components/core/Root/analytics.tsx"
import { routesConfig } from "@/config/routes"
import { hasProperty } from "@/helpers/typeguards"
import { useAuthHook } from "@/hooks/auth"
import { useLogger } from "@/hooks/useLogger"

import * as value from "./value"

interface UseLoginHookType {
  form: UseFormReturn<value.Encoder.EncoderType>
  isLoading: boolean
  onChangePasswordInputType: VoidFunction
  onSubmit: FormEventHandler<HTMLFormElement>
  passwordInputType: "password" | "text"
  submitError: string | null
}

const useLoginHook = (): UseLoginHookType => {
  const [passwordInputType, setPasswordInputType] = useState<
    "password" | "text"
  >("password")
  const [isLoading, setIsLoading] = useState(false)
  const [submitError, setSubmitError] = useState<string | null>(null)

  const { t } = useTranslation()
  const { login } = useAuthHook()
  const location = useLocation()
  const navigate = useNavigate()
  const log = useLogger()
  const { identifyPostHogUser } = useAnalytics()

  const form = useForm<value.Encoder.EncoderType>({
    defaultValues: value.Encoder.defaultValues,
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldFocusError: false,
  })

  const { handleSubmit } = form

  const onChangePasswordInputType = () => {
    setPasswordInputType((prev) => (prev === "password" ? "text" : "password"))
  }

  const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
    try {
      setIsLoading(true)
      const validCredentials = await value.Decoder.schema.parseAsync(data)
      const signInResponse = await login(validCredentials)

      if (checkIfUserNeedsToResetPassword(signInResponse)) {
        navigate(routesConfig.RESET_PASSWORD_CONFIRM + "/first-sign-in")
      } else {
        identifyPostHogUser(validCredentials.username)

        // redirect to the origin page
        const origin =
          location.state?.from?.pathname || routesConfig.AUTOMATIC_CALL_PANEL
        navigate(origin)
      }
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)

      let errMessage = ""

      // Only log to sentry the unknown errors. logError function will not be called in cases when user introduces
      // a wrong user password combination or tries to login too many times with a wrong password.
      if (err instanceof Error) {
        if (err.message === "Password attempts exceeded") {
          errMessage = t("errors.login.passwordAttemptsExceeded")
        } else if (
          err.name === "UserNotFoundException" ||
          err.name === "NotAuthorizedException"
        ) {
          errMessage = t("errors.login.userNotFoundOrUnauthorized")
        } else {
          log.error(err)
        }
      } else {
        t("errors.login.unknownError")
        log.error(err)
      }

      errMessage && setSubmitError(errMessage)
    }
  }

  return {
    form,
    onSubmit: handleSubmit(onSubmit),
    passwordInputType,
    onChangePasswordInputType,
    isLoading,
    submitError,
  }
}

export { useLoginHook }

const checkIfUserNeedsToResetPassword = (signInResponse: SignInOutput) => {
  return (
    hasProperty(
      "isSignedIn",
      (value) => typeof value === "boolean",
    )(signInResponse) &&
    !signInResponse.isSignedIn &&
    signInResponse.nextStep?.signInStep ===
      "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
  )
}
