/* eslint-disable react-refresh/only-export-components */
import { createContext, useContext, useEffect, useRef, useState } from "react"
import { useBeforeUnload } from "react-router"
import { useInterval } from "react-use"
import { useAtom, useAtomValue } from "jotai"
import { RESET } from "jotai/utils"
import invariant from "tiny-invariant"

import { getContactIdFromAgent } from "@/helpers/agent"
import {
  agentAtom,
  callDurationDataAtom,
  currentContactStatusTypeAtom,
  currentContactTypeAtom,
} from "@/helpers/atoms"
import { getTimestamp } from "@/helpers/dateFormat"
import { hasValue, isNullish } from "@/helpers/typeguards"
import { CallDurationData, callDurationDataSchema } from "@/helpers/zodSchemas"
import { useCallPanelLogger } from "@/hooks/useLogger"
import { contactStorageService } from "@/services/localStorageService"

import {
  calculateEndTimestamp,
  getInitialTimer,
  Timer,
} from "./callDurationTimer"

const CallDurationContext = createContext<CallDurationHookType | null>(null)

interface CallDurationHookType {
  clearCallDurationData: VoidFunction
  endTimer: (hasStoredData?: boolean) => void
  startTimer: VoidFunction
  timer: Timer
  updateTimerInState: VoidFunction
}

/**
 * Wrap Call Duration state in a Context wrapper
 * to include the hook in multiple places without resetting the Timer state
 */
function useCallDuration() {
  const callDurationState = useContext(CallDurationContext)
  invariant(
    callDurationState,
    "CallDurationContext should have been initialized", // `CallDurationContext.Provider` is supposed to be called with a `value`, always.
  )

  return callDurationState
}

function CallDurationProvider({ children }: { children: React.ReactNode }) {
  const callDurationState = useCallDurationState()

  return (
    <CallDurationContext.Provider value={callDurationState}>
      {children}
    </CallDurationContext.Provider>
  )
}

/**
 * Basic hook related to the "atom with storage" provided by Jotai
 * not related to the Timer displayed in the UI
 */
export function useCallDurationData() {
  const [callDurationData, setCallDurationData] = useAtom(callDurationDataAtom)

  function clearCallDurationData() {
    setCallDurationData(RESET)
  }

  return {
    callDurationData,
    clearCallDurationData,
    setCallDurationData,
  }
}

const useCallDurationState = (): CallDurationHookType => {
  const agent = useAtomValue(agentAtom)
  const currentContactType = useAtomValue(currentContactTypeAtom)
  const currentContactStatusType = useAtomValue(currentContactStatusTypeAtom)
  const { callDurationData, clearCallDurationData, setCallDurationData } =
    useCallDurationData()

  const contactIdForCallDuration = getContactIdFromAgent(agent)
  const prevContactIdForCallDuration = useRef(contactIdForCallDuration)
  const validCallDuration = callDurationDataSchema.safeParse(callDurationData)
  const storedContactId = validCallDuration.success
    ? validCallDuration.data.contact_id
    : undefined

  const log = useCallPanelLogger()
  const callDurationKey = contactStorageService.getCallDurationKey()

  const initialTimer = getInitialTimer({
    callDurationData,
    contactIdForCallDuration,
    callDurationKey,
    log,
  })

  const [timer, setTimer] = useState<Timer>(initialTimer)
  const [isIntervalRunning, setIsIntervalRunning] = useState<boolean>(false)

  /**
   * In manual call, the contactIdDuration is set after the timer has started.
   * So we need to update the callDurationData in local storage when the contactId is set.
   *
   * In the case of automatic call, the prevContactIdForCallDuration is initialized as
   * contactIdForCallDuration. So it would not be affected
   */
  useEffect(() => {
    if (
      contactIdForCallDuration !== undefined &&
      prevContactIdForCallDuration.current === undefined
    ) {
      prevContactIdForCallDuration.current = contactIdForCallDuration
      const timestamp = new Date()
      setCallDurationData({
        contact_id: contactIdForCallDuration,
        call_start_timestamp: timestamp.toISOString(),
        call_end_timestamp: null,
      })
    } else if (
      contactIdForCallDuration !== prevContactIdForCallDuration.current
    ) {
      prevContactIdForCallDuration.current = contactIdForCallDuration
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactIdForCallDuration])

  const isVoiceCall = currentContactType === connect.ContactType.VOICE

  const isActiveVoiceCall =
    isVoiceCall &&
    (currentContactStatusType === connect.ContactStateType.CONNECTING ||
      currentContactStatusType === connect.ContactStateType.CONNECTED)

  const isMismatchedContactIds =
    hasValue(contactIdForCallDuration) &&
    hasValue(storedContactId) &&
    contactIdForCallDuration !== storedContactId

  const shouldEraseStoredData = !isActiveVoiceCall && isMismatchedContactIds
  const shouldStartTimer = isNullish(storedContactId) || isMismatchedContactIds

  /**
   * Put in local storage the timestamp when the agent makes an outbound call / answers an inbound call
   * In normal usage, the call duration data in local storage should be undefined when the agent has not started / received a call.
   */
  const initCallDurationData = () => {
    if (isNullish(contactIdForCallDuration)) {
      return
    }

    // Log the error case when the contact id in local storage does not match the current contact id
    // but proceed to overwrite the existing value in local storage

    if (isMismatchedContactIds) {
      // TODO: restore this as Sentry warning when we have resolved: POC-5577,POC-5424 & POC-5675
      // eslint-disable-next-line no-console
      console.warn(
        "Contact ID in local storage does not match the current contact ID in agent object.",
        {
          [callDurationKey]: callDurationData,
          contactIdForCallDuration,
        },
      )
    }

    const callStartTimestamp = new Date()

    const newCallDurationData = {
      contact_id: contactIdForCallDuration,
      call_start_timestamp: callStartTimestamp.toISOString(),
      call_end_timestamp: null,
    }

    setCallDurationData(newCallDurationData)
  }

  const setCallDurationEndTimestamp = () => {
    if (!validCallDuration.success) {
      return
    }

    if (isMismatchedContactIds) {
      // TODO: restore this as Sentry warning when we have resolved: POC-5577,POC-5424 & POC-5675
      // eslint-disable-next-line no-console
      console.warn(
        "Contact ID in local storage does not match the current contact ID in agent object.",
        {
          contactIdForCallDuration,
          [callDurationKey]: callDurationData,
        },
      )

      return
    }

    // When refreshing the page in ACW state:
    // The call has already ended and we have the end timestamp in local storage; Do nothing
    if (hasValue(validCallDuration.data.call_end_timestamp)) {
      return
    }

    const endTimestamp = isActiveVoiceCall
      ? new Date()
      : calculateEndTimestamp(validCallDuration.data, timer)

    const updatedCallDurationData: CallDurationData = {
      ...validCallDuration.data,
      call_end_timestamp: endTimestamp.toISOString(),
    }

    return setCallDurationData(updatedCallDurationData)
  }

  const clearCallDurationEndTimestamp = () => {
    if (!validCallDuration.success) {
      return
    }

    const updatedCallDurationData: CallDurationData = {
      ...validCallDuration.data,
      call_end_timestamp: null,
    }

    return setCallDurationData(updatedCallDurationData)
  }

  const getCallStartTimestamp = (): Date | undefined => {
    if (!validCallDuration.success) {
      return
    }

    if (isMismatchedContactIds) {
      // TODO: restore this as Sentry warning when we have resolved: POC-5577,POC-5424 & POC-5675
      // eslint-disable-next-line no-console
      console.warn(
        "Contact ID in local storage does not match the current contact ID in agent object.",
        {
          contactIdForCallDuration,
          [callDurationKey]: callDurationData,
        },
      )

      return
    }

    return new Date(validCallDuration.data.call_start_timestamp)
  }

  /**
   * Function responsible for displaying the call duration updates in the call panel UI.
   */
  const updateTimerInState = () => {
    const date = new Date()
    const callStartTimestamp = getCallStartTimestamp()

    if (isNullish(callStartTimestamp)) {
      return
    }

    const newTimer = getTimestamp(callStartTimestamp, date)

    setTimer(() => newTimer)
  }

  const startTimer = () => {
    initCallDurationData()
    setIsIntervalRunning(true)
  }

  const restartTimer = () => {
    setIsIntervalRunning(true)
    clearCallDurationEndTimestamp()
  }

  const endTimer = () => {
    setIsIntervalRunning(false)

    const hasStoredData = hasValue(callDurationData)

    if (hasStoredData) {
      setCallDurationEndTimestamp()
    }
  }

  /**
   * Hook to update the timer in state every second, while in a call.
   * The timer is to be displayed in the call panel UI.
   */
  useInterval(
    () => {
      try {
        updateTimerInState()
      } catch (error) {
        endTimer()
        log.error(error)
      }
    },
    isIntervalRunning ? 1000 : null,
  )

  /**
   * Hook to update the local storage with the call duration end time when the agent refreshes the page
   */
  useBeforeUnload(() => endTimer())

  /**
   * Hook to restart the timer in the edge case of an agent refreshing the page while in a call.
   */
  useEffect(() => {
    if (isIntervalRunning) {
      return
    }

    // if the contact ids don't match just start a new timer otherwise restart it
    if (isActiveVoiceCall) {
      shouldStartTimer ? startTimer() : restartTimer()
    }
  }, [isActiveVoiceCall, isIntervalRunning, shouldStartTimer]) // eslint-disable-line react-hooks/exhaustive-deps

  // Erase the stored call duration data in local storage when it is mismatching the current contact ID
  useEffect(() => {
    if (shouldEraseStoredData) {
      clearCallDurationData()
    }
  }, [shouldEraseStoredData]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    timer,
    startTimer,
    endTimer,
    updateTimerInState,
    clearCallDurationData,
  }
}

export { CallDurationProvider, type Timer, useCallDuration }
