import { useSearchParams } from "react-router"

import { updateSearchParams } from "@/helpers/url"

import { searchOptionsSchema, StudentSearchOptions } from "../api/students"

export function useStudentsSearch() {
  const [params, setParams] = useSearchParams()

  const searchOptions = searchOptionsSchema.parse({
    statuses: params.getAll("statuses") || [],
    universities: params.getAll("universities") || [],
    search_query: params.get("search_query") || "",
  })

  const onChange =
    <K extends keyof StudentSearchOptions>(key: K) =>
    (value: StudentSearchOptions[K]) => {
      setParams((current) => {
        updateSearchParams(current, key, value)

        return current
      })
    }

  return { searchOptions, onChange }
}
