import { useEffect } from "react"
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router"
import { RouterProvider } from "react-router/dom"
import { extraErrorDataIntegration } from "@sentry/integrations"
import * as Sentry from "@sentry/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import { useUpdateChecker } from "@/components/core/AppUpdateChecker/hook-app-update"
import ForbidAuthedAgentRoute from "@/components/core/ForbidAuthedAgentRoute"
import ForbidOnlineAgentRoute from "@/components/core/ForbidOnlineAgentRoute"
import ProtectedRoute from "@/components/core/ProtectedRoute"
import { Toaster } from "@/components/core/Toaster"
import { TooltipProvider } from "@/components/ui/tooltip"
import { config } from "@/config/index"
import { routesConfig } from "@/config/routes"
import { CampaignsRoot } from "@/pages/Campaigns/campaigns-root"
import CustomConnectPanel from "@/pages/CustomConnectPanel"
import ErrorPage from "@/pages/ErrorPage"
import Login from "@/pages/Login"
import { NoCallListPage } from "@/pages/NoCallList/no-call-list-page"
import PerformanceRoot from "@/pages/Performance/performance-root"
import ResetPassword from "@/pages/ResetPassword"
import SyncAgents from "@/pages/SyncAgents/sync-agents"
import { TasksRenewalPage } from "@/pages/Tasks/tasks-renewal-page"

import { AdminRoute } from "./admin-route"
import { PHProvider } from "./analytics"
import { AppShell } from "./app-shell"
import { beforeAddRecordingEvent, beforeSend } from "./helpers"

Sentry.init({
  dsn: config.sentryDSN, // Disable sentry by setting DSN to empty string or undefined
  environment: config.sentryEnvironment,
  initialScope: { tags: { repository: "cci-call-panel" } },
  integrations: [
    // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#usage-with-react-router-64-data-api
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    extraErrorDataIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      maskAllInputs: true,
      blockAllMedia: false,
      unmask: ["[data-unmask]"],
      beforeAddRecordingEvent,
      // Allow sending to Sentry the network details for these URLs
      networkDetailAllowUrls: [/api\.((development|local)\.)?calliope-ai\.jp/i],
      networkCaptureBodies: true,
      networkRequestHeaders: ["Cache-Control"],
      networkResponseHeaders: ["Referrer-Policy"],
    }),
  ],
  // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/#set-up
  replaysSessionSampleRate: 0.0, // Increase up to 1.0 to stream normal agent sessions to Sentry (no errors encountered in the session)
  replaysOnErrorSampleRate:
    config.sentryEnvironment === "production" ? 1.0 : 0.0,
  beforeSend,
  // List of errors to ignore (do not send to sentry) https://docs.sentry.io/platforms/javascript/configuration/filtering/#filtering-error-events
  ignoreErrors: [
    /no ongoing contact found with id .* for agent/i, // Internal CCP iframe error when agent goes offline with a task. We end the task back-end side.
  ],
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
})

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const routes = [
  {
    path: routesConfig.AUTOMATIC_CALL_PANEL,
    element: (
      <ProtectedRoute>
        <AppShell />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />, // Error for path not found
    children: [
      {
        // TODO: Improve error page on child routes; see https://reactrouter.com/en/main/start/tutorial#pathless-routes
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <CustomConnectPanel /> },
          {
            path: "performance/*",
            element: (
              <ForbidOnlineAgentRoute>
                <PerformanceRoot />
              </ForbidOnlineAgentRoute>
            ),
          },
          {
            path: "sync-agents",
            element: (
              <AdminRoute>
                <SyncAgents />
              </AdminRoute>
            ),
          },
          {
            path: "no-call-list",
            element: (
              <AdminRoute>
                <NoCallListPage />
              </AdminRoute>
            ),
          },
          {
            path: "tasks",
            element: (
              <AdminRoute>
                <TasksRenewalPage />
              </AdminRoute>
            ),
          },
          {
            path: "campaigns/*",
            element: (
              <ForbidOnlineAgentRoute>
                <CampaignsRoot />
              </ForbidOnlineAgentRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "login",
    element: (
      <ForbidAuthedAgentRoute>
        <Login />
      </ForbidAuthedAgentRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "reset-password/*",
    element: (
      <ForbidAuthedAgentRoute>
        <ResetPassword />
      </ForbidAuthedAgentRoute>
    ),
    errorElement: <ErrorPage />,
  },
]

const queryClient = new QueryClient()
const router = sentryCreateBrowserRouter(routes)

const Root = () => {
  useUpdateChecker()

  return <App />
}

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <TooltipProvider>
        <PHProvider>
          <RouterProvider router={router} />
        </PHProvider>
      </TooltipProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      <Toaster />
    </QueryClientProvider>
  )
}

export default Root
