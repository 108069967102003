import { useTranslation } from "react-i18next"
import { format } from "date-fns"

import { ColumnGroupDef, DashboardTable } from "@/components/DashboardTable"
import { PaginationState } from "@/components/pagination/pagination-state"
import { EventStatusPill } from "@/components/status-pill"
import { TableLink } from "@/components/table-helpers"
import { CopyIdButton } from "@/components/ui/copy-id-button"
import { formatFullName } from "@/helpers/names"

import { CallReport } from "./call-history-schemas"

type Props = PaginationState & {
  callReports: CallReport[]
  isLoading?: boolean
}
export function CallHistoryTable({ callReports, ...otherProps }: Props) {
  const columnGroups = useColumns()

  return (
    <DashboardTable
      columnGroups={columnGroups}
      items={callReports}
      getItemId={(callReport) =>
        callReport.group_id + callReport.campaign_id + callReport.student_id
      }
      {...otherProps}
    />
  )
}

function useColumns() {
  const { t } = useTranslation()
  const colGroups: ColumnGroupDef<CallReport>[] = [
    {
      header: t("campaigns.details.students.name"),
      cols: [
        {
          id: "name",
          cell: (callReport) => (
            <TableLink
              to={`/campaigns/${callReport.campaign_id}/students/${callReport.student_id}`}
            >
              {formatFullName(callReport.first_name, callReport.last_name)}
            </TableLink>
          ),
        },
      ],
    },
    {
      header: t("caseHistory.entorId"),
      cols: [
        {
          id: "legacy_id",
          className: "min-w-32",
          cell: (callReport) => (
            <CopyIdButton
              className="w-full"
              uuid={callReport.legacy_id.toString()}
            />
          ),
        },
      ],
    },
    {
      header: t("campaigns.preview.table.phoneNumber"),
      cols: [
        {
          id: "phone_number",
          cell: (callReport) => <>{callReport.phone_number}</>,
        },
      ],
    },
    {
      header: t("caseHistory.activityFeed.status"),
      cols: [
        {
          id: "status",
          cell: (callReport) => <EventStatusPill value={callReport.status} />,
        },
      ],
    },
    {
      header: t("caseHistory.activityFeed.lastUpdate"),
      cols: [
        {
          id: "reported_at",
          cell: (callReport) => (
            <>{format(new Date(callReport.reported_at), "yyyy/MM/dd HH:mm")}</>
          ),
        },
      ],
    },
    {
      header: t("caseHistory.campaign"),
      cols: [
        {
          id: "campaign_id",
          cell: (callReport) => <>{callReport.campaign_id}</>,
        },
      ],
    },
    {
      header: t("caseHistory.activityFeed.callType.label"),
      cols: [
        {
          id: "call_type",
          cell: (callReport) => (
            <>
              {t(
                `caseHistory.activityFeed.callType.values.${
                  callReport.call_type || "noCall"
                }`,
              )}
            </>
          ),
        },
      ],
    },
  ]

  return colGroups
}
