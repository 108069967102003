import { useEffect } from "react"
import { useLocation } from "react-router"
import { useQuery } from "@tanstack/react-query"
import { useAtom, useAtomValue, useSetAtom } from "jotai"

import { useAxiosClientContext } from "@/components/core/AxiosInstanceProvider/useAxiosClient"
import { config } from "@/config/index"
import { getAgentId } from "@/helpers/agent"
import { agentAtom, agentProfileAtom, modalModeAtom } from "@/helpers/atoms"
import { toError } from "@/helpers/error"
import { hasValue, isNullish } from "@/helpers/typeguards"
import { useReactQueryOptions } from "@/hooks/reactQueryOptions"
import { ModalMode } from "@/types/modes"

import * as value from "./value"

interface LayoutHookType {
  agentProfile: value.Decoder.ConnectUserInformation | null
  modalMode: ModalMode
}

const useLayoutHook = (): LayoutHookType => {
  const axiosClient = useAxiosClientContext()
  const location = useLocation()
  const agent = useAtomValue(agentAtom)
  const [modalMode, setModalMode] = useAtom(modalModeAtom)
  const setAgentProfile = useSetAtom(agentProfileAtom)
  const { retry } = useReactQueryOptions()

  const agentARN = agent?.getConfiguration().agentARN
  const connectUserId = getAgentId(agentARN)

  useEffect(() => {
    setModalMode(null)
  }, [location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  const queryFn = async () => {
    try {
      if (isNullish(connectUserId)) {
        throw new Error("connectUserId is nullish")
      }

      const userInformationResponse =
        await axiosClient.get<value.Decoder.ConnectUserInformation>(
          `https://${config.apiEndpoint}/agents`,
          {
            params: {
              connect_user_id: connectUserId,
            },
          },
        )

      const validUserInformation =
        await value.Decoder.userInformationResponseSchema.parseAsync(
          userInformationResponse.data,
        )

      setAgentProfile(validUserInformation.data)

      return validUserInformation.data
    } catch (err) {
      const errorInstance = toError(err)
      throw new Error(
        `Failed to fetch agent profile: ${errorInstance.message}`,
        { cause: errorInstance },
      )
    }
  }

  // TODO: loading state and error state
  const { data: agentProfile } = useQuery({
    queryKey: ["agentProfile", connectUserId],
    queryFn,
    initialData: null,
    enabled: hasValue(connectUserId),
    retry,
  })

  return { agentProfile, modalMode }
}

export { useLayoutHook }
