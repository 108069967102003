import { isDateRange } from "react-day-picker"
import { useSearchParams } from "react-router"
import { endOfDay, format, parseISO, startOfDay } from "date-fns"

import { DateRange } from "@/components/ui/calendar"
import { isUndefined } from "@/helpers/typeguards"
import { useQueryAPI } from "@/hooks/query-api"

import { Decoder } from "./value"

const staleTime = 1000 * 60 * 5 // data is considered stale after 5 minutes, to avoid "spamming" too much the API server

type FilterParams = {
  agent_id?: string
  campaign_id?: string
  dateRange?: DateRange
}

function useFilterParams(): [
  FilterParams,
  (newParams: Partial<FilterParams>) => void,
] {
  const [searchParams, setSearchParams] = useSearchParams()

  const updateQueryParams = (newParams: Partial<FilterParams>) => {
    for (const key in newParams) {
      const value = newParams[key as keyof FilterParams]

      if (isUndefined(value)) {
        if (key === "dateRange") {
          searchParams.set("date_from", "")
          searchParams.set("date_to", "")
        } else {
          searchParams.set(key, "")
        }
      } else if (
        isDateRange(value) &&
        !isUndefined(value.from) &&
        !isUndefined(value.to)
      ) {
        searchParams.set(
          "date_from",
          format(startOfDay(value.from), "yyyy-MM-dd"),
        )
        searchParams.set("date_to", format(endOfDay(value.to), "yyyy-MM-dd"))
      } else if (typeof value === "string") {
        searchParams.set(key, value)
      }
    }
    setSearchParams(searchParams)
  }

  const currentParams: FilterParams = {}
  const date_from = searchParams.get("date_from")
  const date_to = searchParams.get("date_to")
  if (date_from && date_to) {
    currentParams.dateRange = {
      from: startOfDay(parseISO(date_from)),
      to: endOfDay(parseISO(date_to)),
    }
  }
  const agent_id = searchParams.get("agent_id")
  if (agent_id) {
    currentParams.agent_id = agent_id
  }
  const campaign_id = searchParams.get("campaign_id")
  if (campaign_id) {
    currentParams.campaign_id = campaign_id
  }

  return [currentParams, updateQueryParams]
}

function useFetchCampaignPerformance(
  dateRange?: DateRange,
  campaign_id?: string,
) {
  return useQueryAPI({
    apiEndPoint: "/metrics/campaigns/summary",
    params: {
      campaign_id: campaign_id || null,
      date_from: dateRange?.from,
      date_to: dateRange?.to,
    },
    options: {
      staleTime,
    },
    schema: Decoder.campaignMetricsResponseSchema,
  })
}

function useFetchCampaignPerformanceByAgent(
  campaignId: string | undefined,
  dateRange?: DateRange,
) {
  return useQueryAPI({
    apiEndPoint: "/metrics/campaigns/agent-breakdown",
    options: {
      staleTime,
    },
    params: {
      campaign_id: campaignId,
      date_from: dateRange?.from,
      date_to: dateRange?.to,
    },
    schema: Decoder.agentMetricsResponseSchema,
  })
}

function useFetchAgentsPerformance(dateRange?: DateRange, agentId?: string) {
  return useQueryAPI({
    apiEndPoint: "/metrics/agents/summary",
    options: {
      staleTime,
    },
    params: {
      connect_user_id: agentId || null, // get all agents and filter in frontend
      date_from: dateRange?.from,
      date_to: dateRange?.to,
    },
    schema: Decoder.agentMetricsResponseSchema,
  })
}

function useFetchAgentPerformanceByCampaign(
  agentId: string | undefined,
  dateRange?: DateRange,
) {
  return useQueryAPI({
    apiEndPoint: "/metrics/agents/campaign-breakdown",
    options: {
      staleTime,
    },
    method: "POST",
    params: {
      connect_user_id: agentId,
      date_from: dateRange?.from,
      date_to: dateRange?.to,
    },
    schema: Decoder.campaignBreakdownMetricsResponseSchema,
  })
}

export {
  useFetchAgentPerformanceByCampaign,
  useFetchAgentsPerformance,
  useFetchCampaignPerformance,
  useFetchCampaignPerformanceByAgent,
  useFilterParams,
}
