import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router"
import { endOfDay, format, startOfDay } from "date-fns"

import { AdminRoute } from "@/components/core/Root/admin-route"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { routesConfig } from "@/config/routes"
import { cn } from "@/helpers/classNames"
import { useAgentProfile } from "@/hooks/agent-profile"

import DetailsByCampaignPage from "./agents/details-by-campaign"
import { AgentMetricsContent } from "./agents/metrics-by-agents-page"
import { DetailsByAgent } from "./campaigns/details-by-agent"
import { CampaignMetricsContent } from "./campaigns/metrics-by-campaign-page"

export default function PerformanceRoot() {
  const { agentProfile } = useAgentProfile()

  return (
    <Routes>
      <Route
        path={routesConfig.AUTOMATIC_CALL_PANEL}
        element={<PerformanceTables />}
      >
        <Route path="" element={<Navigate to="campaigns" replace />}></Route>
        <Route
          path="campaigns"
          element={
            <DefaultDateRangeWrapper>
              <CampaignMetricsContent />
            </DefaultDateRangeWrapper>
          }
        ></Route>
        <Route
          path="staff"
          element={
            <AdminRoute>
              <DefaultDateRangeWrapper>
                <AgentMetricsContent />
              </DefaultDateRangeWrapper>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="personal"
          element={
            <DefaultDateRangeWrapper>
              <DetailsByCampaignPage
                entry="personal"
                agentProfile={agentProfile}
              />
            </DefaultDateRangeWrapper>
          }
        ></Route>
      </Route>
      <Route
        path="campaigns/:campaign_id"
        element={
          <DefaultDateRangeWrapper>
            <DetailsByAgent />
          </DefaultDateRangeWrapper>
        }
      ></Route>
      <Route
        path="staff/:staff_id"
        element={
          <AdminRoute>
            <DefaultDateRangeWrapper>
              <DetailsByCampaignPage entry="staff" />
            </DefaultDateRangeWrapper>
          </AdminRoute>
        }
      ></Route>
    </Routes>
  )
}

function DefaultDateRangeWrapper({ children }: PropsWithChildren) {
  const [searchParams] = useSearchParams()

  if (
    searchParams.get("date_from") === null &&
    searchParams.get("date_to") === null
  ) {
    const today = new Date()
    const dateFrom = format(startOfDay(today), "yyyy-MM-dd")
    const dateTo = format(endOfDay(today), "yyyy-MM-dd")

    return (
      <Navigate
        to={`${location.pathname}?date_from=${dateFrom}&date_to=${dateTo}`}
        replace
      />
    )
  }

  return <>{children}</>
}

function getTableValue(path: string) {
  if (path.includes("personal")) {
    return "personal"
  } else if (path.includes("staff")) {
    return "staff"
  }

  return "campaigns"
}

function PerformanceTables() {
  const { t } = useTranslation()
  const { isManager } = useAgentProfile()
  const location = useLocation()
  const navigate = useNavigate()

  const tabValue = getTableValue(location.pathname)

  const handleTabChange = (value: string) => {
    if (value === tabValue) return
    if (value === "staff" && isManager) {
      navigate("/performance/staff")
    } else if (value === "personal" && !isManager) {
      navigate("/performance/personal")
    } else {
      navigate("/performance/campaigns")
    }
  }

  return (
    <main className="flex h-full flex-col gap-y-4 px-8 pt-10">
      <div className="flex items-center justify-between">
        <h1 className="font-h2-bold text-neutral-700" data-unmask>
          {t("sidebarMenu.performance")}
        </h1>
      </div>
      <Tabs
        value={tabValue}
        onValueChange={handleTabChange}
        className={cn("w-full")}
      >
        <TabsList>
          <TabsTrigger value="campaigns">
            {t("performance.tabs.campaign")}
          </TabsTrigger>
          {isManager ? (
            <TabsTrigger value="staff">
              {t("performance.tabs.staff")}
            </TabsTrigger>
          ) : (
            <TabsTrigger value="personal">
              {t("performance.tabs.personal")}
            </TabsTrigger>
          )}
        </TabsList>
        <TabsContent value="campaigns">
          {tabValue === "campaigns" && <Outlet />}
        </TabsContent>
        <TabsContent value="staff">
          {tabValue === "staff" && <Outlet />}
        </TabsContent>
        <TabsContent value="personal">
          {tabValue === "personal" && <Outlet />}
        </TabsContent>
      </Tabs>
    </main>
  )
}
