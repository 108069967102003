import { Dispatch, SetStateAction } from "react"
import { useLifecycles, useTimeoutFn } from "react-use"
import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useAtomValue } from "jotai"
import { z } from "zod"

import { useAxiosClientContext } from "@/components/core/AxiosInstanceProvider/useAxiosClient"
import { config } from "@/config/index"
import { agentAtom } from "@/helpers/atoms"
import { useReactQueryOptions } from "@/hooks/reactQueryOptions"
import { useCallPanelLogger } from "@/hooks/useLogger"
import { AgentDisplayMode } from "@/pages/CustomConnectPanel/hook"
import { type QueueRefillErrorContext } from "@/pages/CustomConnectPanel/value"

export type RefillStatus =
  | "error"
  | "no-work"
  | "rate-limit-error"
  | "scheduled-work"
  | "scheduling-work"

const refillResponseSchema = z.object({
  success: z.string(),
  data: z.object({
    posted_campaignIds: z.string().array(),
    campaign_ids_to_refill: z.string().array(),
  }),
})

/**
 * Hook that calls the /campaign-filters/request-queue-refill API endpoint. This hook is called when the agent is in the busy state and no contact
 * has been incoming in a number of seconds. The outcome of the request will determine the next step for the agent:
 * - if more students are available to be scheduled, the API endpoint will call the student distributor to schedule more tasks for the agent. The
 * incoming tasks will change the call view state machine from request-work to busy.
 * - if no more work available for the agent, the call view state machine will change from request-work to available.
 * - if the API rate limit is reached, the call view state machine will change from request-work to rate-limit-error.
 * - if the API request fails, the call view state machine will change from request-work to error.
 * The error states will display a button enabling the agent to refresh the page.
 * @param setDisplayMode - function to set the call view state machine, as described above
 *
 *
 */
const useRequestWork = ({
  setDisplayMode,
}: {
  setDisplayMode: Dispatch<SetStateAction<AgentDisplayMode>>
}) => {
  const axiosClient = useAxiosClientContext()
  const { retry } = useReactQueryOptions()
  const log = useCallPanelLogger()

  const agent = useAtomValue(agentAtom)
  const agentConfig = agent?.getConfiguration()

  // All routing profiles have a queue with null name
  // the queue name == the campaign name
  const queues =
    agentConfig?.routingProfile.queues
      .map((queue) => queue.name)
      .filter(Boolean) ?? []

  const showCallPanelResetView = (errorContext: QueueRefillErrorContext) => {
    return setDisplayMode({
      current: "reset",
      state: { currentCase: null, errorContext },
    })
  }

  const mutationFn = async (): Promise<void> => {
    try {
      if (!queues.length) {
        throw new Error("No queues found on the agent object.")
      }

      const response = await axiosClient.post(
        "/campaign-filters/request-queue-refill",
        { campaign_ids: queues },
      )

      const validResponse = await refillResponseSchema.parseAsync(response.data)
      const successMessage = validResponse.success

      const isNoWork =
        successMessage === "No queues to refill for the provided campaign ids"
      const isAlreadyScheduled =
        successMessage ===
        "Work has already been scheduled for the provided campaign ids"
      const isSchedulingWork =
        successMessage === "Successfully initiated the campaign refill process"

      if (isSchedulingWork) {
        return
      }

      if (isAlreadyScheduled) {
        return showCallPanelResetView("queue-refill-already-scheduled")
      }

      if (isNoWork) {
        return setDisplayMode({ current: "available" })
      }

      return showCallPanelResetView("queue-refill-error")
    } catch (err) {
      log.error(err, {
        message_ctx: "Failed to refill agent queues",
        queues,
      })

      if (err instanceof AxiosError && err.response?.status === 429) {
        return showCallPanelResetView("queue-refill-rate-limit-error")
      }

      return showCallPanelResetView("queue-refill-error")
    }
  }

  const { mutateAsync } = useMutation({
    mutationFn,
    retry,
  })

  // Hook to display the call panel reset view if the query to refill the queues fails to respond in due time
  const [isReady, cancel, reset] = useTimeoutFn(
    () => showCallPanelResetView("queue-refill-error"),
    config.resetPanelTimeout,
  )

  const refillQueues = async () => {
    await mutateAsync()
    // Cancel the timer that triggers the call panel reset view when the request to refill the queues is successful
    if (isReady() === false) {
      cancel()
    }
  }

  useLifecycles(
    // On page mount trigger an API request to refill the queues
    // Student distributor has a locking mechanism to prevent multiple requests at the same time
    () => refillQueues(),
    // On page unmount cancel the timer that triggers the call panel reset view
    // Reset the timeout only when the timeout has not been reached
    () => {
      if (isReady() === false) {
        reset()
      }
    },
  )
}

export { useRequestWork }
