import { FunctionComponent } from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link } from "react-router"

import Input from "@/components/core/Input"
import Icon from "@/components/Icon"
import { Button } from "@/components/ui/button"
import { cn } from "@/helpers/classNames"
import { useFormValidation } from "@/hooks/formValidation"

import { useResetPasswordHook } from "./hook"

const styles = {
  wrapper: cn("flex min-h-screen justify-center bg-neutral-200 py-20"),
  container: cn(
    "flex w-full flex-col items-center justify-start gap-y-6 px-4 sm:w-2/3 lg:w-2/5",
  ),
  brand: cn("mb-2"),
  textContainer: cn("flex min-w-fit flex-col items-center gap-y-2"),
  title: cn("font-body-xlarge-bold text-neutral-600"),
  subtitle: cn("font-body-medium-regular text-neutral-600"),
  errorContainer: cn(
    "font-h4-bold flex w-full items-center gap-x-1 rounded border border-danger-300 bg-danger-200 px-4 py-6 text-neutral-100",
  ),
  formContainer: cn(
    "flex w-full min-w-fit flex-col items-center justify-center gap-y-2 rounded border border-neutral-300 bg-neutral-100 p-8 shadow-card",
  ),
  inputsContainer: (isDisabled: boolean) =>
    cn([
      "flex w-full flex-col gap-y-4",
      isDisabled && "pointer-events-none opacity-50",
    ]),
  inputLabel: cn("font-label-small mb-1 text-neutral-600"),
  submit: cn("mt-6 w-full max-w-[240px]"),
  forgotContainer: cn("font-body-medium-regular text-center text-neutral-600"),
  contactManager: cn("font-body-medium-bold"),
  successText: cn(
    "font-body-medium-regular w-full self-start text-success-300",
  ),
  tryAgainButton: cn("-ml-2 self-start"),
  goBackLink: cn("font-button-small p-1 text-primary-300"),
}

const ResetPassword: FunctionComponent = () => {
  const { t } = useTranslation()
  const { enableSubmit, form, isDisabled, isLoading, mode, onSubmit } =
    useResetPasswordHook()
  const { validEmailAddress } = useFormValidation()
  const {
    clearErrors,
    control,
    formState: { errors },
  } = form

  const shouldRestartProcess =
    mode.current === "error" && mode.state.errorType === "unrecoverable"

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.brand}>
          <Icon name="logo" size="logo-size" />
        </div>
        <div className={styles.textContainer} data-unmask>
          <p className={styles.title}>{t("resetPassword.request.title")}</p>
          <p className={styles.subtitle}>
            {t("resetPassword.request.subtitle")}
          </p>
        </div>
        {mode.current === "error" && (
          <div className={styles.errorContainer} data-unmask>
            <Icon name="alert" size="sm" />
            <h4>{mode.state.errorMessage}</h4>
          </div>
        )}
        <form className={styles.formContainer} onSubmit={onSubmit}>
          <div className={styles.inputsContainer(isDisabled)}>
            <Controller
              control={control}
              rules={{ required: true, validate: { validEmailAddress } }}
              name="email"
              render={({ field: { name, onBlur, onChange, ref, value } }) => (
                <Input
                  name={name}
                  label="login.emailLabel"
                  type="email"
                  placeholder="login.emailPlaceholder"
                  onBlur={onBlur}
                  onChange={onChange}
                  errors={errors}
                  clearErrors={clearErrors}
                  forwardedRef={ref}
                  value={value}
                  orientation="vertical"
                  labelClassName={styles.inputLabel}
                />
              )}
            />
          </div>
          {mode.current === "success" && (
            <>
              <span className={styles.successText} data-unmask>
                {t("resetPassword.request.success")}
              </span>
              <Button
                size="sm"
                type="button"
                variant="ghost"
                onClick={enableSubmit}
                className={cn([
                  styles.tryAgainButton,
                  "hover:bg-transparent font-label-small text-primary-300 hover:text-primary-400",
                ])}
              >
                {t("resetPassword.request.tryAgain")}
              </Button>
            </>
          )}

          {shouldRestartProcess ? (
            <div className={styles.goBackLink} data-unmask>
              <Link to="/login">
                <span>{t("errors.errorPage.goBack")}</span>
              </Link>
            </div>
          ) : (
            <Button
              size="lg"
              type="submit"
              className={styles.submit}
              isDisabled={isDisabled}
              isLoading={isLoading}
              loadingMessage={t("resetPassword.request.loadingText")}
            >
              {t("global.button.resetPassword")}
            </Button>
          )}
        </form>
        <div className={styles.forgotContainer} data-unmask>
          <p>{t("login.help")}</p>
          <p className={styles.contactManager}>{t("login.contactManager")}</p>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
